import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";

const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  background-color: #eee;
  padding: 15px;
`;

const TitleHeight = 35;

const Content = styled.div`
  margin: 0 auto;
  max-width: 720px;
  background-color: white;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .video {
    width: 240px;
    height: ${TitleHeight + 135}px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;

    @media screen and (min-width: 321px) {
      width: 320px;
      height: ${TitleHeight + 180}px;
    }

    @media screen and (min-width: 721px) {
      width: 640px;
      height: ${TitleHeight + 360}px;
    }

    & > .title {
      height: ${TitleHeight}px;
      padding: 5px;
      color: teal;
      text-align: center;
      font-size: 16px;
    }
  }
`;

class VideosPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      videos: []
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });

    const { appActions } = this.props;
    appActions.getVideoList().then(videos => this.setState({ videos }));
  }

  render() {
    let { mounted, videos } = this.state;

    if (!mounted) {
      return <h2>Loading...</h2>;
    }

    return (
      <Wrapper>
        <Content>
          {videos.map(video => (
            <div key={video.id} className="video">
              <ReactPlayer
                url={video.url}
                width="100%"
                height={`calc(100% - ${TitleHeight}px)`}
                style={{ backgroundColor: "#ccc" }}
                controls
              />
              <div key={video.id} className="title">
                {video.title}
              </div>
            </div>
          ))}
        </Content>
      </Wrapper>
    );
  }
}

export default withPage(
  connect(
    null,
    ActionCreator
  )(VideosPage)
);
